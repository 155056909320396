import { Img } from "../image";
import noResults from "../../assets/noResults.png"

export const NoResult = () => {
  return (
    <section class="w-full p-4 my-4 flex flex-col justify-center items-center">
      <Img src={noResults} layout="fullWidth" class="max-w-md" />
      <p class="text-sm sm:text-lg">
        Oops, something went wrong - We couldn't find what you were looking for!{" "}
      </p>
    </section>
  );
};