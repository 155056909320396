import { Show } from "solid-js";
import { Meta } from "@solidjs/meta";
import { isServer } from "solid-js/web";
import Body from "~/components/Body";
import Button from "~/components/Button";
import { CoreValueCard, Odometer } from "~/components/cvc";
import { Icon } from "solid-heroicons";
import { printer } from "solid-heroicons/outline";
import { NoResult } from "~/components/cvc";
import "./cvcID.css";
import { RouteSectionProps, createAsync, useNavigate } from "@solidjs/router";
import { getCVC, getCVCCounts } from "~/services/cvc";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";

export default function CVC(props: RouteSectionProps) {
  const { addError } = useErrorContext();
  const navigate = useNavigate();
  const cvc = createAsync(
    async () => {
      try {
        return await getCVC(props.params.cvc_id);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[[cvc_id].tsx]: Error caught in createAsync: ", err);
          // TODO - Sentry
        }
        addError(err, {
          severity: "warning",
          showDetails: true,
          title: "CVC Error",
          message:
            "An error occurred while retrieving this Core Value Card. Please try again. If the error persists, kindly contact customer support",
          actions: [
            {
              label: "Refresh",
              action: () => {
                window.location.reload();
              },
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    },
    {
      deferStream: true,
    }
  );

  const cvcCounts = createAsync(async () => {
    try {
      const data = await getCVCCounts();
      if (Object.keys(data).length > 0) {
        const total = Object.values(data).reduce((count, curr) => count + curr);
        return { ...data, Total: total };
      }
      return data;
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[(cvc).tsx]: Error caught in createAsync: ", err);
        // TODO - Sentry
        // ? Not worth displaying error for this, just send report to sentry..
      }
    }
  });

  return (
    <Body class="px-4 min-h-[60vh]">
      <Meta name="robots" content="noindex,nofollow" />
      <main class="max-w-4xl mx-auto my-8">
        <Show when={cvc() && cvc()?.CoreValue} fallback={<NoResult />}>
          <h2 class="print:hidden text-2xl sm:text-4xl font-bold text-center">
            You've received a Core Value Card!
          </h2>

          <Show when={cvcCounts() && cvcCounts()?.Total}>
            <div class="flex flex-col items-center  mx-auto my-4 py-2">
              <Odometer
                number={(cvcCounts()!.Total?.toString() ?? "0").padStart(
                  4,
                  "0"
                )}
                class="odometer-classic"
                speed={1000}
                size={40}
              />
              <p>CVC's sent and counting!</p>
            </div>
          </Show>

          <section class="w-full bg-roma-grey print:bg-transparent rounded-md shadow-md print:shadow-none p-8 my-4 print:p-0 print:my-0 flex print:flex-col print:gap-4 justify-center items-center relative">
            <Show when={cvc()?.CoreValue}>
              <CoreValueCard
                flippable
                val={cvc()?.CoreValue as string}
                toName={cvc()?.ToName}
                fromName={cvc()?.FromName}
                message={cvc()?.Message}
              />
            </Show>
            <button
              title="Print"
              class="print:hidden w-10 h-10 absolute right-9 bottom-9 hover:scale-[1.1]"
              onClick={() => {
                if (!isServer) window.print();
              }}
            >
              <Icon
                path={printer}
                stroke-width={2}
                class="w-8 h-8 text-roma-blue"
              />
            </button>
          </section>
          <Button rootClass="cvc_send_button" href="/cvc?team-internal=true">
            Send a Core Value Card!
          </Button>
        </Show>
      </main>
    </Body>
  );
}
